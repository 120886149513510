import React from 'react'
import Link from 'next/link'
import { Box, CircularProgress, Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from 'ui'
import Logo from '../icons/logo.svg'

const useStyles = makeStyles((theme) => ({
  aboveTheFold: {
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    flexDirection: 'column',
    margin: theme.spacing(2, 0, 10, 0)
  },
  main: {
    width: 500,
    padding: theme.spacing(3),
    textAlign: 'center'
  },
  loaderContainer: {
    margin: theme.spacing(2)
  },
  logo: {
    height: 64,
    width: 64,
    marginBottom: theme.spacing(2)
  }
}))

export default function BuildUnavailableLayout({ loading, title, text, actionText, actionHref }) {
  const classes = useStyles()

  return (
    <div className={classes.aboveTheFold}>
      <div className={classes.center}>
        <Paper elevation={5} className={classes.main}>
          <Logo className={classes.logo} />
          {title && (
            <div>
              <Typography variant="h2">{title}</Typography>
            </div>
          )}
          {loading && (
            <div className={classes.loaderContainer}>
              <CircularProgress size={50} />
            </div>
          )}
          <div>{text}</div>
          {actionText && (
            <Box marginTop={2}>
              <Link href={actionHref} passHref>
                <Button>{actionText}</Button>
              </Link>
            </Box>
          )}
        </Paper>
      </div>
    </div>
  )
}
