import React, { useMemo } from 'react'
import { Avatar, IconButton, Tooltip, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Help as DeleteIcon, FileCopyTwoTone } from '@material-ui/icons'
import { gql } from '@apollo/client'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import DeployUserLogo from './icons/deploy-token-avatar.svg'
import copyToClipboard from './util/copyToClipboard'
import hslFromString from './util/hslFromString'

export const ACTOR_AVATAR_FRAGMENT = gql`
  fragment ActorAvatar on Actor {
    deployToken {
      id
      name
    }
    organizationApiToken {
      id
      name
    }
    user {
      id
      avatarUrl
      email
      fullname
    }
  }
`

const useStyles = makeStyles((theme) => ({
  avatar: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    '& img': {
      position: 'absolute'
    }
  },
  icon: {
    height: 32,
    width: 32
  },
  thumbnail: {
    height: 48,
    width: 48
  },
  full: {
    height: 100,
    width: 100
  }
}))

function getUserInitials(user) {
  return (
    user &&
    user.email
      .split('@')[0]
      .split('.')
      .map((words) => words[0].toUpperCase())
      .join('')
  )
}

export default function ActorAvatar({
  actor = {},
  variant,
  showTooltip = false,
  onClick,
  ...otherProps
}) {
  const classes = useStyles()

  let initials = null

  const backgroundColor = useMemo(() => {
    return hslFromString(actor.user?.fullname || actor.deployToken?.name || '')
  }, [actor.deployToken?.name, actor.user?.fullname])

  if (actor.user) {
    const user = actor.user
    initials = getUserInitials(user)
    const avatar = (
      <Avatar
        src={user.avatarUrl}
        style={{ backgroundColor, cursor: onClick ? 'pointer' : 'auto' }}
        className={clsx(classes.avatar, classes[variant])}
        onClick={onClick}
        {...otherProps}
      >
        {initials}
      </Avatar>
    )
    if (showTooltip) {
      return (
        <Tooltip
          interactive
          title={
            <>
              <Typography variant="body1">{user.fullname}</Typography>
              <Typography variant="body2">
                {user.email}
                <IconButton size="small" onClick={() => copyToClipboard(user.email)}>
                  <FileCopyTwoTone fontSize="small" />
                </IconButton>
              </Typography>
            </>
          }
        >
          {avatar}
        </Tooltip>
      )
    }
    return avatar
  }

  const tokenActor = actor.deployToken || actor.organizationApiToken
  if (tokenActor) {
    // The actor is a deploy token
    return (
      <div title={tokenActor.name} className={classes.icon}>
        <DeployUserLogo className={classes.icon} style={{ color: backgroundColor }} />
      </div>
    )
  }
  return (
    <div title={'deleted user or token'} className={classes.icon}>
      <Avatar className={classes.icon}>
        <DeleteIcon />
      </Avatar>
    </div>
  )
}

ActorAvatar.propTypes = {
  actor: PropTypes.object,
  variant: PropTypes.oneOf(['icon', 'thumbnail', 'full'])
}

ActorAvatar.defaultProps = {
  variant: 'thumbnail'
}
