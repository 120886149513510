import { stringifyUrl } from 'query-string'
import { useRouter } from 'next/router'

export const organizationUrl = (org, query = {}) =>
  stringifyUrl(
    {
      url: `/org/${encodeURIComponent(org.slug)}`,
      query
    },
    { skipNull: true }
  )

export const teamUrl = (team) => `/${encodeURIComponent(team.slug)}`

export const siteUrl = (team, site) => `${teamUrl(team)}/${encodeURIComponent(site.slug)}`

export const environmentUrl = (team, site, environment) =>
  `${siteUrl(team, site)}/env/${encodeURIComponent(environment.name)}`

// Returns site base path, based on current route params
export const useSiteUrl = () => {
  const {
    query: { siteSlug, teamSlug }
  } = useRouter()

  // Usually site is read from current route, but when not on a site route we
  // need to specific a site
  return (site) => {
    return siteUrl({ slug: teamSlug }, site ? site : { slug: siteSlug })
  }
}

export const useBuildUrl = () => {
  const baseEnvPath = useEnvironmentUrl()

  return (build, { environment, site } = {}) => {
    const envUrl = baseEnvPath(environment || build.environment, { site })

    return `${envUrl}/builds/${encodeURIComponent(build.number.toString())}`
  }
}

export const useEnvironmentUrl = () => {
  const baseSitePath = useSiteUrl()

  return (environment, { site, query } = {}) => {
    const siteUrl = baseSitePath(site)
    const url = `${siteUrl}/env/${encodeURIComponent(environment.name)}`

    return stringifyUrl({ url, query }, { skipNull: true })
  }
}
